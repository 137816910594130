var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Collapse, IconButton } from '@mui/material';
//
import { useSettingsContext } from '../settings';
import Iconify from '../iconify';
//
import StyledNotistack from './styles';
export default function SnackbarProvider(_a) {
    var children = _a.children;
    var themeDirection = useSettingsContext().themeDirection;
    var isRTL = themeDirection === 'rtl';
    var notistackRef = useRef(null);
    var onClose = function (key) { return function () {
        notistackRef.current.closeSnackbar(key);
    }; };
    return (_jsxs(_Fragment, { children: [_jsx(StyledNotistack, {}), _jsx(NotistackProvider, __assign({ ref: notistackRef, dense: true, maxSnack: 5, preventDuplicate: true, autoHideDuration: 3000, TransitionComponent: isRTL ? Collapse : undefined, variant: "success" // Set default variant
                , anchorOrigin: { vertical: 'top', horizontal: 'right' }, iconVariant: {
                    info: _jsx(SnackbarIcon, { icon: "eva:info-fill", color: "info" }),
                    success: _jsx(SnackbarIcon, { icon: "eva:checkmark-circle-2-fill", color: "success" }),
                    warning: _jsx(SnackbarIcon, { icon: "eva:alert-triangle-fill", color: "warning" }),
                    error: _jsx(SnackbarIcon, { icon: "eva:alert-circle-fill", color: "error" }),
                }, 
                // With close as default
                action: function (key) { return (_jsx(IconButton, __assign({ size: "small", onClick: onClose(key), sx: { p: 0.5 } }, { children: _jsx(Iconify, { icon: "eva:close-fill" }) }))); } }, { children: children }))] }));
}
function SnackbarIcon(_a) {
    var icon = _a.icon, color = _a.color;
    return (_jsx(Box, __assign({ component: "span", sx: {
            mr: 1.5,
            width: 40,
            height: 40,
            display: 'flex',
            borderRadius: 1.5,
            alignItems: 'center',
            justifyContent: 'center',
            color: "".concat(color, ".main"),
            bgcolor: function (theme) { return alpha(theme.palette[color].main, 0.16); },
        } }, { children: _jsx(Iconify, { icon: icon, width: 24 }) })));
}
